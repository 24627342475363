import React, {useState, useEffect} from 'react';
import {Button, Container, ButtonGroup, BtnCellRenderer} from 'reactstrap';
import {AgGridReact} from 'ag-grid-react';
import {Link, useHistory} from 'react-router-dom';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import AppNavbar from './AppNavbar';

const UserList = () => {
    const history = useHistory();

    function BtnCellRenderer() {}
   BtnCellRenderer.prototype.init = function(params) {
        this.params = params;
        console.log("params" + params.data.modifica);
        this.eGui = document.createElement('button');
        let text = params.data.modifica? ' Modifica' : 'Dettaglio';
        this.eGui.innerHTML = text;
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.eGui.addEventListener('click', this.btnClickedHandler);
    }

    BtnCellRenderer.prototype.getGui = function() {
        return this.eGui;
    }
    BtnCellRenderer.prototype.destroy = function() {
        this.eGui.removeEventListener('click', this.btnClickedHandler);
    }
    BtnCellRenderer.prototype.btnClickedHandler = function(event) {
        this.params.clicked(this.params.value);
    }

    function test(){
        this.eGui.innerHTML = 'Dettaglio';
    }

    function logout(){
        const sessionToken = sessionStorage.getItem('token');
        fetch('/B2BUserManager/users/logout', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({sessionToken}),
        })

        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('locId');
        sessionStorage.removeItem('accId');
        sessionStorage.removeItem('username');
        sessionStorage.removeItem('awp');
        history.push('/login');
    }

    function GoToUserDetail(id){
        history.push('/users/' + id);
    }

    const [rowData, setRowData] = useState([]);
    const [columnDefs] = useState([
        {headerName: "Username", field: "username"},
        {headerName: "Email", field: "email", flex:2},
        {headerName: "Nome", field: "nome"},
        {headerName: "Cognome", field: "cognome"},
        {headerName: "Stato", field: "stato"},
        {headerName: "", field: "idUtente", cellRenderer: BtnCellRenderer, cellRendererParams: {
                clicked: function(field) {
                    GoToUserDetail(`${field}`);
                }}}
    ]);

    useEffect(() => {

        const locId = sessionStorage.getItem('locId');
        const userId = sessionStorage.getItem('userId');
        const token = sessionStorage.getItem('token');
        // Fetch dynamic data from an API or define it statically
        const fetchData = async () => fetch('/B2BUserManager/users', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({locId, userId, token})
        })
            .then(response => response.json())
            .then(data => {
                const autorizzato = data.autorizzazione
                console.log(autorizzato);
                if (autorizzato === true) {
                    console.log("is Autorizzato");
                    setRowData(data.usersList);
                } else {
                    console.log("is not Autorizzato");
                    this.props.history.push('/login');
                }
            });
        fetchData();
    }, []);

    return (
        <div>
            <AppNavbar/>
            <Container fluid>
                <br/>
                <div align="center">
                    <h4>Utenti Censiti</h4>
                </div>
                <div align="right">
                    <Button color="black">{sessionStorage.getItem('username')}</Button>
                </div>
                <div align="right">
                    <Button color="black">{sessionStorage.getItem('locId')}</Button>
                </div>
                <div align="right">
                    <Button color="link" tag={Link} to="#" onClick={() => logout()}>Logout</Button>
                </div>
                <div>
                    <Button color="success" tag={Link} to="/users/new">Nuovo Utente</Button>
                </div>
                <br/>
                <div className="ag-theme-alpine" style={{ width: '100%', height: '100vh',marginRight: 0, marginLeft: 0}}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        pagination={true}
                        paginationPageSize={20}
                        rowHeight={40}
                    />
                </div>
            </Container>
        </div>
    );
};

export default UserList;
